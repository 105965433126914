import React, { HTMLAttributes } from 'react';

import * as S from './JumpBackInButton.styles';
import { Icon, Text } from '@Cortex';
import ChevronIcon from '../assets/chevron_icon.svg';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
}

export const JumpBackInButton = ({ isOpen, ...props }: Props) => {
  return (
    <S.Container
      isOpen={isOpen}
      {...props}
      aria-expanded={isOpen}
      aria-label={isOpen ? 'Minimize Jump Back In' : 'Show Jump Back In'}
      role="button"
      tabIndex={0}
    >
      <S.ButtonText>JUMP BACK IN</S.ButtonText>
      <S.IconContainer isOpen={isOpen}>
        <Icon alt="" aria-hidden="true" isSquare size={16} src={ChevronIcon} />
      </S.IconContainer>
    </S.Container>
  );
};
