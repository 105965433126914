import { EffectLevels } from '@Model';

export const getNeuralEffectLevelDescription = (selectedNEL: string): string => {
  switch (selectedNEL) {
    case 'Low':
      return "Text that explains more about the Low Neural Effect Level that's been selected.";
    case 'Medium':
      return 'Our standard level of brainwave technology is a great place to start.';
    case 'High':
      return 'Perfect if you need extra stimulation or have attentional challenges. (ADHD or simliar).';
    default:
      return 'Our standard level of brainwave technology is a great place to start.';
  }
};
