import { Assets } from '../../../../utils/assets';

export const getNeuralEffectLevelImage = (selectedNEL: string) => {
  switch (selectedNEL) {
    case 'Low':
      return Assets.images.firstActivityModal.neuralEffectLevel.low;
    case 'Medium':
      return Assets.images.firstActivityModal.neuralEffectLevel.medium;
    case 'High':
    default:
      return Assets.images.firstActivityModal.neuralEffectLevel.high;
  }
};
