import styled from 'styled-components';

export const StepIndicators = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StepIndicator = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
}>`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isActive, isCompleted }) =>
    isActive || isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};

  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: 4rem;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background: ${({ theme, isActive, isCompleted }) =>
      isActive || isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};
    transition: transform 0.3s ease-in;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex: 1;
    height: 8px;
    margin: 0px 3px;
    max-width: 64px;
  }
`;
