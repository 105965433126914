import { BRAZE_BLACKLISTED_EVENTS } from '@Analytics';
import * as Braze from '@braze/web-sdk';
import * as Sentry from '@sentry/react';
import Amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import { UAParser } from 'ua-parser-js';

import {
  AnalyticsEvents,
  AnalyticsEventsProperties,
  AnalyticsUserProperties,
  AnalyticsUserPropertiesType,
  GACategories,
} from '../../types/utils/analytics';
import { Logger } from '../../utils/logger';
import DateUtil from '../date';

type ReportTo = 'amplitude' | 'braze' | 'facebook_pixel' | 'all';

class AnalyticsClass {
  private parser = new UAParser();

  /**
   * Creates user in the analytics platforms.
   * Sets user id and email for both Amplitude and Braze,
   * alse sets default user properties with default values.
   * @param {string} id User's UID
   * @param {string} email User's email
   */
  createUser = (id: string, email: string, otherProps: AnalyticsUserProperties = {}) => {
    try {
      Amplitude.getInstance().setUserId(id);
      Braze.changeUser(id);
      Sentry.setUser({ id, email });

      this.setUserEmail(email);

      // set default values
      const defaultUserProperties = this.getDefaultUserProperties();
      this.setUserProperties({ ...defaultUserProperties, ...otherProps });
    } catch (e) {
      Logger.error(new Error('Failed to create analytics user.'), { reason: e });
    }
  };

  /**
   * Sets user id for both Amplitude and Braze.
   * @param {string} id User's UID.
   */
  setUserId = (id: string) => {
    try {
      Amplitude.getInstance().setUserId(id);
      Braze.changeUser(id);
      Sentry.setUser({ id });
    } catch (e) {
      Logger.error(new Error('Failed to set analytics user id.'), { reason: e });
    }
  };

  /**
   * Attaches an email to a specific user.
   * @param {string} email User's email.
   */
  setUserEmail = (email: string) => {
    try {
      Amplitude.getInstance().setUserProperties({ email });
      Braze.getUser()?.setEmail(email);
      Sentry.setUser({ email });
    } catch (e) {
      Logger.error(new Error('Failed to set analytics user email.'), { reason: e });
    }
  };

  /**
   * Attaches properties to a user object for both Amplitude and Braze.
   * @param {AnalyticsUserProperties} obj User properties
   */
  setUserProperties = (obj: AnalyticsUserProperties) => {
    try {
      Amplitude.getInstance().setUserProperties(obj);

      for (const [key, value] of Object.entries(obj)) {
        Braze.getUser()?.setCustomUserAttribute(key, value!);
      }
    } catch (e) {
      Logger.error(new Error('Failed to set analytics custom user properties.'), { reason: e });
    }
  };

  /**
   * Logs a single event to both Amplitude and Braze.
   * @param {keyof EventNames} event one of event names that are declated in Events object
   */
  logEvent = (event: AnalyticsEvents) => {
    try {
      Amplitude.getInstance().logEvent(event);
      Braze.logCustomEvent(event);
    } catch (e) {
      Logger.error(new Error('Failed to log analytics event.'), { reason: e });
    }
  };

  /**
   * Logs a single event with properties to both Amplitude and Braze.
   * @param {keyof EventNames} event one of event names that are declated in Events object
   * @param {object} props Extra information that needs to be passed with the event.
   */
  logEventWithProperties = (
    event: AnalyticsEvents,
    props: Partial<AnalyticsEventsProperties>,
    reportTo: ReportTo = 'all',
  ) => {
    try {
      const time = new Date();
      const device = this.parser.getDevice();
      const OS = this.parser.getOS();
      let platformType = device.type ? device.type : 'unknown';

      if (OS.name?.match(/mac|windows/i)) {
        platformType = 'desktop';
      }

      const properties = {
        ...props,
        local_time: DateUtil(time).getLocalTimeStr(),
        local_date: DateUtil(time).getLocalDateStr(),
        local_timestamp: DateUtil(time).getLocalTimestampStr(),
        local_timezone: DateUtil(time).getLocalTimezone(),
        platform: 'web',
        platform_type: platformType,
        platform_device: device.model ? device.model : OS.name ? OS.name : 'unknown',
        UTC_date: DateUtil(time).getUTCDateStr(),
        UTC_time: DateUtil(time).getUTCTimeStr(),
        UTC_timestamp: DateUtil(time).getUTCTimestampStr(),
      };

      if (reportTo === 'amplitude' || reportTo === 'all') {
        Amplitude.getInstance().logEvent(event, properties);
      }

      if (reportTo === 'braze' || reportTo === 'all') {
        if (!BRAZE_BLACKLISTED_EVENTS.includes(event)) {
          Braze.logCustomEvent(event, properties);
        }
      }

      if (reportTo === 'facebook_pixel' || reportTo === 'all') {
        ReactPixel.trackCustom(event);
      }
    } catch (e) {
      Logger.error(new Error('Failed to log analytics event with properties.'), { reason: e });
    }
  };

  /**
   * Increments one specific User property (attribute) that is a number.
   * @param {UserPropertyNames} propertyName Users property (attribute) name
   * @param {number} incrementBy A number to increment by (use negative number to decrement).
   */
  incrementUserProperty = (propertyName: keyof AnalyticsUserProperties, incrementBy: number) => {
    try {
      const identify = new Amplitude.Identify().add(propertyName, incrementBy);
      Amplitude.identify(identify);

      Braze.getUser()?.incrementCustomUserAttribute(propertyName, incrementBy);
    } catch (e) {
      Logger.error(new Error('Failed to increment user property.'), { reason: e });
    }
  };

  setUserProperty = (propertyName: string, value: boolean) => {
    try {
      Amplitude.getInstance().setUserProperties({ [propertyName]: value });
      Braze.getUser()?.setCustomUserAttribute(propertyName, value);
    } catch (e) {
      Logger.error(new Error('Failed to set user property.'), { reason: e });
    }
  };

  /**
   * Logs event to Google Analytics
   * @param category
   * @param action
   * @param lable
   */
  logEventGA = (category: GACategories, action: string, label?: string) => {
    try {
      const googleAnalytics = (
        window as unknown as {
          ga?: (a: string, b: string, c: string, d: string, e?: string) => any;
        }
      ).ga;
      if (googleAnalytics) {
        googleAnalytics('send', 'event', category, action, label);
      }
    } catch (e) {
      Logger.error(new Error('Failed to log ga event.'), { reason: e });
    }
  };

  /**
   * Helper funtion to get all user default properties at one
   * with a possibility of overriding some values.
   * @param params
   * @returns Returns Users default pr0perties for Analytics
   */
  getDefaultUserProperties = (
    params: AnalyticsUserProperties = {},
  ): AnalyticsUserPropertiesType => {
    return {
      account_creation: 'email',
      acquisition_source: 'normal',
      current_platform: 'web',
      email: 0,
      enterprise_id: 0,
      first_name: 0,
      initial_promotion: '',
      initial_referrer: '',
      language: 0,
      most_used_state: 0,
      partner_id: 0,
      purchase_intent: 'low',
      push_enabled: false,
      timezone: 0,
      traffic_ad_campaign: 0,
      traffic_ad_id: 0,
      traffic_ad_source: 0,
      core_onboarding_first_session: '',
      ...params,
    };
  };
}

export const Analytics = new AnalyticsClass();
