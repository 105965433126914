import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { NoData } from './components/NoData';
import { RecentTracks } from './components/RecentTracks';

import * as S from './styles';
import { JumpBackInButton } from './components';
import { RecentSession } from './components/RecentSession';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { RecentSessionType } from './types';
import { createDynamicSessionFromJumpBackIn } from '../../../../actions/sessionManager';
import { useNavigate } from 'react-router-dom';
import { Serving, Track } from '@Model';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';

type Props = {
  onOpen: () => void;
  onClose: () => void;
  onSelectPreviewTrack: (track: Track | Serving) => void;
  onOpenTrackInfoModal: () => void;
};

export const JumpBackInModal = ({
  onOpen,
  onClose,
  onSelectPreviewTrack,
  onOpenTrackInfoModal,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);
  const ref = useRef(null);
  const recentTracksRef = useRef<HTMLDivElement | null>(null);
  const isMobileView = useIsMobileView();

  const recentSessions = useSelector((state: RootReducerType) => state.recentSessions);

  const handleOpen = () => {
    setIsOpen(state => !state);
    setBodyMounted(true);
    onOpen();
  };

  const handleClose = () => {
    setIsOpen(state => !state);
    onClose();
  };

  const unmountBody = () => {
    if (!isOpen) {
      setBodyMounted(false);
    }
  };

  const handleRecentSessionClick = (recentSession: RecentSessionType) => {
    dispatch(createDynamicSessionFromJumpBackIn({ recentSession, navigate }));
  };

  const handleTrackClickMore = (track: Track | Serving) => {
    onOpenTrackInfoModal();
    onSelectPreviewTrack(track);
  };

  return (
    <>
      <S.Container isMobileView={isMobileView} isOpen={isOpen} onTransitionEnd={unmountBody}>
        <S.ButtonContainer isOpen={isOpen}>
          <JumpBackInButton
            aria-expanded={isOpen}
            aria-label={isOpen ? 'Minimize Jump Back In' : 'Show Jump Back In'}
            isOpen={isOpen}
            role="button"
            tabIndex={0}
            onClick={isOpen ? handleClose : handleOpen}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                isOpen ? handleClose() : handleOpen();
              }
            }}
          />
        </S.ButtonContainer>

        <Transition in={bodyMounted} mountOnEnter nodeRef={ref} timeout={100} unmountOnExit>
          {state => (
            <S.TransitionWrapper ref={ref} animationState={state} onClick={handleClose}>
              <S.ContentWrapper
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {recentSessions.status !== 'loading' && !recentSessions.ids.length ? (
                  <NoData />
                ) : (
                  <>
                    <S.TitleWrapper>
                      <S.Title aria-level={2} role="heading">
                        RECENT SESSIONS
                      </S.Title>
                      {recentSessions.status === 'loading' ? (
                        <S.LoadingIconAnimating
                          aria-label="Loading recent sessions"
                          role="status"
                        />
                      ) : null}
                    </S.TitleWrapper>
                    <S.RecentSessionsContainer
                      ref={recentTracksRef}
                      aria-label="Recent sessions"
                      role="list"
                    >
                      {recentSessions.ids.map((id, i) => {
                        const recentSession = recentSessions.results[id];
                        return (
                          <S.RecentSessionWrapper
                            key={`${recentSession.id}_${i}`}
                            isRight={i % 2 === 0}
                            role="listitem"
                          >
                            <RecentSession
                              recentSession={recentSession}
                              onClick={() => handleRecentSessionClick(recentSession)}
                            />
                          </S.RecentSessionWrapper>
                        );
                      })}
                    </S.RecentSessionsContainer>
                    <S.TitleWrapper>
                      <S.Title aria-level={2} role="heading">
                        RECENT TRACKS
                      </S.Title>
                    </S.TitleWrapper>
                    <RecentTracks isFromJumpBackIn onTrackClickMore={handleTrackClickMore} />
                  </>
                )}
              </S.ContentWrapper>
            </S.TransitionWrapper>
          )}
        </Transition>
      </S.Container>
    </>
  );
};
