import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootReducerType } from 'src/reducers';

import { useQuery } from '../../../hooks';
import { useTeamSubscriptionInfo } from '../../../hooks/useHasTeamSubscription';
import { useGetAvailableStripeCouponPromotion } from '../lenses';
import { MembershipSources } from '../reducer';

export function useRedirectUserToPaymentOnPromotion() {
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const loggedInUserId = useSelector((state: RootReducerType) => state.user.info?.id);
  const { status } = useSelector((state: RootReducerType) => state.app);
  const membership = useSelector((state: RootReducerType) => state.membership);
  const { belongsToTeam, isDataReady } = useTeamSubscriptionInfo();

  useEffect(() => {
    if (
      status === 'ready' &&
      availablePromotion &&
      loggedInUserId &&
      membership.source !== MembershipSources.Loading &&
      !location.pathname.includes('/activate') &&
      !location.pathname.includes('/welcome') &&
      !location.pathname.includes('/join') && // if currently joining the team
      !location.pathname.includes('/joined') && // if currently joined team success screen
      isDataReady &&
      !belongsToTeam
    ) {
      navigate(`/${availablePromotion.redirectToPath || 'payment'}?${query.toString()}`, {
        state: location.state, // keep state when redirecting the user (e.g. to keep trial offering)
      });
    }
  }, [status, availablePromotion, loggedInUserId, membership.source, belongsToTeam, isDataReady]);
}
