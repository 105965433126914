import styled from 'styled-components';
import { FontColors, Text, TextBold } from '@Cortex';
import { Assets } from '../../../utils/assets';

export const Container = styled.div`
  align-items: center;
  background-attachment: fixed;
  background-color: #0d0c11;
  background-image: url(${Assets.images.blurBg.url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1.25rem 5rem;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
`;

export const Screens = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  transform: translateX(${({ isActive }) => (isActive ? '-50vw' : '50vw')});
  transition: transform 0.5s ease-in-out;
  width: 200vw;
`;

export const Screen = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 5rem;
  width: 100vw;
`;

export const BackgroundBlur = styled.img`
  filter: blur(15rem);
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 75vh;

  animation: rotation 10s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const HeaderText = styled(TextBold)`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 2rem;
  }
`;

export const HeaderSubtext = styled(Text)`
  color: ${FontColors.WhiteSecondary};
  font-size: 1.25rem;
  margin-bottom: 3.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const TipsContainer = styled.div`
  background: rgba(13, 12, 17, 0.5);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  padding: 3rem 0;
  margin-bottom: 3.5rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    gap: 1.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;

export const TipContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 2rem;
  width: 33.3%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const TipContainerHeader = styled(TextBold)`
  color: ${FontColors.WhiteSecondary};
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  margin-bottom: 0.5rem;
`;

export const TipContainerHeaderSubtext = styled(TextBold)`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    font-size: 1rem;
  }
`;

export const StepImage = styled.img`
  border-radius: 8px;
  height: 15rem;
  object-fit: cover;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    height: 8rem;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const Description = styled(Text)`
  color: ${FontColors.WhiteSecondary};
  font-size: 1rem;
  font-style: normal;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const Divider = styled.div`
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const PaymentContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: inline-flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  animation: fade-up 1s ease-in-out;

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const CheckIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  object-fit: contain;
`;

export const PaymentText = styled(Text)`
  color: ${FontColors.White};
  font-size: 0.875rem;
`;
