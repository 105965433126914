import { Action } from '@reduxjs/toolkit';
import { put, select, takeLatest, throttle } from 'redux-saga/effects';

import * as analyticsActions from '../actions/analytics';
import * as userActions from '../actions/user';
import { RequestMethods } from '../api/client/types';
import { RootReducerType } from '../reducers';
import { userSliceActions } from '../reducers/user';
import { UserInteractionType } from '../types/user';
import { Logger } from '../utils/logger';
import { requestSaga, SuccessResponseType } from './httpRequest';
import { Analytics } from '../utils/analytics';

function* setUserInteractionSaga(action: Action<UserInteractionType>) {
  if (userActions.setInteraction.match(action)) {
    yield put(userSliceActions.setInteraction(action.payload));
  }
}

function* getUserInfoSaga(action: Action) {
  try {
    if (userActions.getInfo.match(action)) {
      const { status }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );
      // don't fetch if we are already fetching
      if (status === 'idle') {
        const { result } = yield requestSaga(RequestMethods.GET, '/users/me', undefined, 2);

        yield put(userSliceActions.setUserInfo(result.user));
        yield put(userSliceActions.setMembershipInfo(result.membership));
        if (result.verification) {
          yield put(userSliceActions.setEmailVerificationInfo(result.verification));
        }
        yield put(userSliceActions.setUserUsage(result.usage));
        yield put(userActions.getStreaks({ source: 'get_user_info' }));

        yield put(analyticsActions.setUser({ user: result.user }));
        yield put(userActions.userInfoInitiallyFetched());
      }
    }
  } catch (error) {
    Logger.error(error);
  }
}

type StreaksResponse = {
  result: {
    daily: {
      currentCount: number;
    };
    weekly: {
      currentCount: number;
      comment: string;
      longestCount: number;
    };
  };
};

function* getStreaksSaga(action: Action) {
  if (userActions.getStreaks.match(action)) {
    try {
      Analytics.logEventWithProperties(
        'debug_streak_fetch',
        {
          called_from: action.payload.source,
        },
        'amplitude',
      );

      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );
      const data: SuccessResponseType<StreaksResponse> = yield requestSaga(
        RequestMethods.GET,
        `/users/${info?.id}/streaks`,
        undefined,
        3,
      );
      yield put(userSliceActions.setStreaks(data.result));
    } catch (err) {
      Logger.error(err);
    }
  }
}

export default function* watchUserSaga() {
  yield takeLatest(userActions.setInteraction.type, setUserInteractionSaga);
  yield takeLatest(userActions.getInfo.type, getUserInfoSaga);
  yield throttle(1000 * 60 * 60, userActions.getStreaks.type, getStreaksSaga);
}
