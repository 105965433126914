import { FontColors, TextSemiBold } from '@Cortex';
import { useRef, useState, useCallback, useEffect } from 'react';

import * as S from './TabBar.styles';

interface Props {
  items: string[];
  activeTabIndex: number;
  onSelect: (value: any) => void;
}

export const TabBar = ({ items, onSelect, activeTabIndex }: Props) => {
  const tabBarRef = useRef<HTMLDivElement>(null);
  const [, setRerender] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setRerender(state => !state);
      });
    });

    if (tabBarRef.current) {
      observer.observe(tabBarRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [tabBarRef]);

  return (
    <S.Container ref={tabBarRef} role="tablist">
      {items.map((item, i) => (
        <S.Item
          key={i}
          aria-controls={`${item.toLowerCase()}-panel`}
          aria-selected={activeTabIndex === i}
          data-testid={item}
          isActive={activeTabIndex === i}
          role="tab"
          tabIndex={0}
          type="button"
          onClick={() => {
            onSelect(item);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              onSelect(item);
            }
          }}
        >
          <TextSemiBold
            color={activeTabIndex === i ? FontColors.White : FontColors.WhiteTransparent}
            size="0.875rem"
          >
            {item}
          </TextSemiBold>
        </S.Item>
      ))}
    </S.Container>
  );
};
