import { DynamicMentalState, Serving, Track } from '@Model';

import { getTrackGenreName, getTrackImageUrl, getTrackName } from '@Music';
import { useEffect, useState } from 'react';
import LoadingIcon from '../../components/icons/LoadingIcon';
import DynamicMentalStateCard from '../../domains/Cortex/DynamicMentalStateCard';
import { JumpBackInModal } from '../../domains/Session/components/JumpBackInModal';
import { MiniPlayer } from '../../domains/Session/components/MiniPlayer/MiniPlayer';
import { RecommendedActivitiesReducerType } from '../../reducers/recommendedActivities';
import * as S from './Home.styles';
import { CardHoverState } from './Home.types';
import { TrackInfoModal } from '../../domains/Music/components/TrackInfoModal/TrackInfoModal';
import { RecommendedActivity } from './components/RecommendedActivity';
import { MentalState } from './components/MentalState';
import { trackTimeOfDayExperimentExposure } from '../../domains/Utils/useAmplitudeExperiments';
import SideBarClosedIcon from '../../assets/images/side_bar_closed_icon.svg';
import SideBarOpenIcon from '../../assets/images/side_bar_open_icon.svg';
import { Icon } from '@Cortex';
import { useIsMobileView } from '../../hooks/useIsMobileView';

type Props = {
  hoverState: CardHoverState | string;
  selectedState: string;
  isLoading: boolean;
  isDisplayedRecommendedActivities: boolean;
  isSideDeckOpen: boolean;
  recommendedActivitiesData: RecommendedActivitiesReducerType;
  navigateToDynamicSessionPlayer: (mentalState: DynamicMentalState) => () => void;
  dynamicMentalStates: DynamicMentalState[];
  recommendedActivitiesText: { title: string | null; subtitle: string | null };
  currentTrack?: Track | Serving | null;
  onSetHoverState: (state: CardHoverState) => () => void;
  onClickRecommendedActivity: (activityId: string) => () => void;
  onClickMiniPlayer: () => void;
  onClickSideDeckButton: () => void;
};

export const HomePresentation = (props: Props) => {
  const {
    hoverState,
    selectedState,
    dynamicMentalStates,
    isLoading,
    isDisplayedRecommendedActivities,
    isSideDeckOpen,
    recommendedActivitiesText,
    recommendedActivitiesData,
    currentTrack,
    navigateToDynamicSessionPlayer,
    onSetHoverState,
    onClickRecommendedActivity,
    onClickMiniPlayer,
    onClickSideDeckButton,
  } = props;

  const isMobileView = useIsMobileView();

  useEffect(() => {
    if (isDisplayedRecommendedActivities) {
      trackTimeOfDayExperimentExposure();
    }
  }, [isDisplayedRecommendedActivities]);

  const [jumpBackInStatus, setJumpBackInStatus] = useState<'hidden' | 'minimized' | 'fullscreen'>(
    'minimized',
  );
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [previewTrack, setPreviewTrack] = useState<Track | Serving | null>(null);

  return (
    <S.Container aria-label="Home" role="main">
      <S.Wrapper
        isJumpBackInVisible={jumpBackInStatus === 'fullscreen'}
        selectedState={selectedState}
      >
        {isLoading ? (
          <S.Body>
            <LoadingIcon aria-label="Loading content" role="status" />
          </S.Body>
        ) : (
          <S.Content>
            <S.Header role="banner">
              {!isMobileView && (
                <S.HeaderIconContainer
                  aria-expanded={isSideDeckOpen}
                  aria-label={isSideDeckOpen ? 'Close side panel' : 'Open side panel'}
                  role="button"
                  tabIndex={0}
                  onClick={onClickSideDeckButton}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      onClickSideDeckButton();
                    }
                  }}
                >
                  <Icon
                    aria-hidden="true"
                    size={32}
                    src={isSideDeckOpen ? SideBarClosedIcon : SideBarOpenIcon}
                  />
                  <S.HeaderIconLabel>{isSideDeckOpen ? 'Close' : 'Open'}</S.HeaderIconLabel>
                </S.HeaderIconContainer>
              )}
            </S.Header>
            <S.BodyDynamic>
              {isDisplayedRecommendedActivities ? (
                <S.NewLayoutContainer aria-label="Recommended Activities" role="region">
                  <S.TitleWrapper>
                    <S.Title aria-level={1} role="heading">
                      {recommendedActivitiesText.title}
                    </S.Title>
                    {recommendedActivitiesText.subtitle ? (
                      <S.Subtitle aria-level={2} role="heading">
                        {recommendedActivitiesText.subtitle}
                      </S.Subtitle>
                    ) : null}
                  </S.TitleWrapper>
                  <S.RecommendedActivitiesWrapper role="list">
                    {recommendedActivitiesData.results.map(activity => (
                      <RecommendedActivity
                        activity={activity}
                        aria-label={`Start ${activity.displayValue} activity`}
                        isSmall={Boolean(currentTrack)}
                        onClick={onClickRecommendedActivity(activity.id)}
                      />
                    ))}
                  </S.RecommendedActivitiesWrapper>
                  <S.SeparatorContainer role="separator">
                    <S.Separator />
                    <S.MentalStateTitle>or choose a mental state</S.MentalStateTitle>
                    <S.Separator />
                  </S.SeparatorContainer>
                  <S.MentalStatesContainer aria-label="Mental States" role="list">
                    {(dynamicMentalStates || [])?.map(mentalState => (
                      <MentalState
                        aria-label={`Select ${mentalState.displayValue} mental state`}
                        data-testid={`${mentalState.id}Card`}
                        isActive={hoverState === mentalState.id}
                        mentalState={mentalState}
                        onClick={navigateToDynamicSessionPlayer(mentalState)}
                        onMouseEnter={onSetHoverState(mentalState.id)}
                        onMouseLeave={onSetHoverState('none')}
                      />
                    ))}
                  </S.MentalStatesContainer>
                </S.NewLayoutContainer>
              ) : (
                <S.MentalStatesWrapper aria-label="Mental States" role="list">
                  {(dynamicMentalStates || [])?.map((mentalState, i) => (
                    <DynamicMentalStateCard
                      key={mentalState.id}
                      aria-label={`Go to ${mentalState.displayValue} session`}
                      color={mentalState.primaryColor}
                      data-testid={`${mentalState.id}Card`}
                      imageUrl={mentalState.imageUrl}
                      imageUrlSecondary={mentalState.imageUrlSecondary}
                      isActive={hoverState === mentalState.id}
                      isFullColumnHeight={i === 0}
                      mentalState={mentalState.displayValue}
                      role="button"
                      tabIndex={0}
                      onClick={navigateToDynamicSessionPlayer(mentalState)}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          navigateToDynamicSessionPlayer(mentalState)();
                        }
                      }}
                      onMouseEnter={onSetHoverState(mentalState.id)}
                      onMouseLeave={onSetHoverState('none')}
                    />
                  ))}
                </S.MentalStatesWrapper>
              )}

              {currentTrack ? (
                <S.MiniPlayerWrapper>
                  <MiniPlayer
                    aria-label={`Now playing: ${getTrackName(currentTrack)}`}
                    role="complementary"
                    trackGenre={getTrackGenreName(currentTrack)}
                    trackImageUrl={getTrackImageUrl(currentTrack)}
                    trackName={getTrackName(currentTrack)}
                    onClick={onClickMiniPlayer}
                  />
                </S.MiniPlayerWrapper>
              ) : null}
            </S.BodyDynamic>
          </S.Content>
        )}
      </S.Wrapper>

      <TrackInfoModal
        isMobileFixed={true}
        isOpen={isMoreInfoOpened}
        track={previewTrack}
        width={500}
        onClose={() => setIsMoreInfoOpened(false)}
      />

      <S.JumpBackInContainer
        aria-expanded={jumpBackInStatus === 'fullscreen'}
        aria-label="Jump Back In section"
        role="complementary"
      >
        <JumpBackInModal
          onClose={() => setJumpBackInStatus('minimized')}
          onOpen={() => setJumpBackInStatus('fullscreen')}
          onOpenTrackInfoModal={() => setIsMoreInfoOpened(true)}
          onSelectPreviewTrack={track => setPreviewTrack(track)}
        />
      </S.JumpBackInContainer>
    </S.Container>
  );
};
