import * as S from './StepIndicator.styles';

type Props = {
  activeIndex: number;
  totalSteps: number;
  style?: React.CSSProperties;
};

export function StepIndicator({ activeIndex, totalSteps, style }: Props) {
  return (
    <S.StepIndicators style={style}>
      {[...Array(totalSteps)].map((_, index) => (
        <S.StepIndicator key={index} isActive={index === activeIndex} isCompleted={false} />
      ))}
    </S.StepIndicators>
  );
}
