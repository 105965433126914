import * as S from './AudioControlBar.styles';
import { AudioControls } from './components/AudioControls';

export const AudioControlBar = () => {
  return (
    <S.AudioControlWrapper>
      <AudioControls />
    </S.AudioControlWrapper>
  );
};
