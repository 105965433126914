import { DynamicMentalState } from '@Model';
import * as S from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  mentalState: DynamicMentalState;
  isActive: boolean;
  onClick: () => void;
}

export const MentalState = ({ mentalState, isActive, ...rest }: Props) => {
  return (
    <S.Container
      {...rest}
      key={mentalState.id}
      aria-label={`Go to ${mentalState.displayValue} session`}
      role="listitem"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          rest.onClick?.();
        }
      }}
    >
      <S.Body>
        <S.Title aria-level={3} role="heading">
          {mentalState.displayValue}
        </S.Title>
      </S.Body>
      <S.ImageContainer>
        <S.Image alt={`${mentalState.displayValue} illustration`} src={mentalState.imageUrl} />
        <S.AuraImage aria-hidden="true" src={mentalState.imageUrlSecondary} />
      </S.ImageContainer>
    </S.Container>
  );
};
