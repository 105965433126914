import styled, { css } from 'styled-components';

export const ControlButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    justify-content: flex-end;
  }
`;

export const RepeatRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-bottom: 0;
  }
`;

export const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.2s ease-in-out;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  transition: opacity 0.1s ease-in-out;
`;

export const PreviousIconWrapper = styled(IconWrapper)`
  rotate: 180deg;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
`;

export const PlayPauseButton = styled.button<{ isLoading: boolean }>`
  background: white;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(46px, 8vw - 2rem, 4rem);
  height: clamp(46px, 8vw - 2rem, 4rem);
  border-radius: 100%;
  cursor: pointer;
  margin: 0 clamp(0.25rem, 8vw - 2rem, 2.5rem);
  backdrop-filter: blur(34px);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          &:after {
            animation: rotation 0.8s linear infinite;
            border-radius: 100%;
            border-right: 2px solid transparent;
            border-top: 2px solid black;
            content: '';
            width: clamp(46px, 8vw - 2rem, 3.75rem);
            height: clamp(46px, 8vw - 2rem, 3.75rem);
            position: absolute;

            @keyframes rotation {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        `
      : null}

  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
  }
`;

export const SkipButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 100%;
  opacity: 0.75;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
  }
`;

export const PreviousButton = styled(SkipButton)<{ isDisabled: boolean }>`
  opacity: ${props => (props.isDisabled ? '0.3' : undefined)};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};

  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
  }
`;

export const RepeatButton = styled(SkipButton)<{ isActive: boolean }>`
  position: relative;
  opacity: ${props => (props.isActive ? '1' : undefined)};

  &:after {
    content: '';
    background: ${props => (props.isActive ? '#fff' : 'transparent')};
    height: 3px;
    width: 25%;
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    transition:
      background 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    border-radius: 5px;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }

  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
  }
`;
