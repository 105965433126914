import { Elements } from '@stripe/react-stripe-js';
import { useFeatureFlag, FeatureFlags } from '@Utils';

import stripe, { ELEMENT_OPTIONS } from '../../../../utils/stripe';
import { usePaywallGateway } from './hooks/usePaywallGateway';
import { OnboardingTestimonials } from '../../../Onboarding/types';
import { SingleScreenPaywall } from './variations/SingleScreenPaywall';
import { useTrackSubscriptionImpression } from './hooks/useTrackSubscriptionImpression';
import { useEffect } from 'react';
import { Analytics } from '../../../../utils/analytics';
import { SingleScreenSlideUpPaywall } from './variations/SingleScreenSlideUpPaywall';
import { ExpressCheckoutPaywall } from './variations/ExpressCheckoutPaywall';

type Props = {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete: () => void;
  onClose: () => void;
};

export function PaywallManagerContainer(props: Props) {
  usePaywallGateway();
  useTrackSubscriptionImpression();
  const paywallVariationFlag = useFeatureFlag<
    'consolidated_no_x_with_alert' | 'paywallvariant1b' | 'express_checkout'
  >(FeatureFlags.PaywallVariation);

  useEffect(() => {
    Analytics.setUserProperty(paywallVariationFlag, true);
  }, []);

  if (paywallVariationFlag === 'consolidated_no_x_with_alert') {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <SingleScreenPaywall
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  if (paywallVariationFlag === 'express_checkout') {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <ExpressCheckoutPaywall
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  return (
    <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
      <SingleScreenSlideUpPaywall
        paywallBenefits={props.paywallBenefits}
        paywallTestimonials={props.paywallTestimonials}
        onClose={props.onClose}
        onPaymentComplete={props.onPaymentComplete}
      />
    </Elements>
  );
}
