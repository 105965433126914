import * as S from './FirstSessionModal.styles';
import { Button, ButtonVariants } from '@Cortex';
import { Assets } from '../../../utils/assets';
import ReactModal from 'react-modal';
import { HeaderWithLogoAndClose } from '../../../domains/shared/HeaderWithLogoAndClose';

export type Props = {
  isOpen: boolean;
  isPaymentSaved: boolean;
  onClose: () => void;
};

export const FirstSessionModalDisplay = ({ isOpen, isPaymentSaved, onClose }: Props) => {
  return (
    <ReactModal isOpen={isOpen} style={{ overlay: { zIndex: 10000 } }}>
      <S.Container>
        <S.BackgroundBlur src={Assets.images.firstActivityModal.blur.url} />
        <HeaderWithLogoAndClose padding={'2rem 0'} />

        <SessionScreen onClose={onClose} />

        {isPaymentSaved && (
          <S.PaymentContainer>
            <S.CheckIcon
              alt={Assets.icons.greenCircleCheck.alt}
              src={Assets.icons.greenCircleCheck.url}
            />
            <S.PaymentText>Your payment information was saved.</S.PaymentText>
          </S.PaymentContainer>
        )}
      </S.Container>
    </ReactModal>
  );
};

export function SessionScreen(props: { onClose: () => void }) {
  return (
    <>
      <S.HeaderText>Maximize your focus with Brain.fm</S.HeaderText>
      <S.HeaderSubtext>To get the most out of Brain.fm, follow these quick tips.</S.HeaderSubtext>

      <S.TipsContainer>
        <S.TipContainer>
          <S.TipContainerHeader>STEP 1</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>Time Matters</S.TipContainerHeaderSubtext>
          <S.StepImage alt={Assets.images.threeClocks.alt} src={Assets.images.threeClocks.url} />
          <S.Description>Plan at least 30 minutes of uninterrupted focus time.</S.Description>
        </S.TipContainer>
        <S.Divider />

        <S.TipContainer>
          <S.TipContainerHeader>STEP 2</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>Use Headphones</S.TipContainerHeaderSubtext>
          <S.StepImage
            alt={Assets.images.headphoneEarbud.alt}
            src={Assets.images.headphoneEarbud.url}
          />
          <S.Description>
            Wearing headphones will enhance the brain’s response to the music.
          </S.Description>
        </S.TipContainer>

        <S.Divider />

        <S.TipContainer>
          <S.TipContainerHeader>STEP 3</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>Start Working</S.TipContainerHeaderSubtext>
          <S.StepImage
            alt={Assets.images.workingOnLaptop.alt}
            src={Assets.images.workingOnLaptop.url}
          />
          <S.Description>
            Let the music play in the background while you focus on your tasks.
          </S.Description>
        </S.TipContainer>
      </S.TipsContainer>

      <S.ButtonContainer>
        <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={props.onClose}>
          START LISTENING
        </Button>
      </S.ButtonContainer>
    </>
  );
}
