import { Button, ButtonVariants } from '@Cortex';
import { useOutsideClickRef } from '@Utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { changeSessionDynamicActivity } from '../../../../actions/sessionManager';
import { RootReducerType } from '../../../../reducers';
import { Analytics } from '../../../../utils/analytics';
import { Events } from '../../../../utils/analytics/events';
import * as S from './ActivitySelection.styles';
import { useDynamicActivitiesByMentalStateId } from '../../../../api/modules/DynamicActivities';
import { ActivitySettingsDynamicDisplay } from '../ActivitySettings/ActivitySettingsDynamic.display';
import { DynamicActivity } from '@Model';
import { getActivitySelectionState, handleActivityAnalytics } from './lib/activityHelpers';

interface Props {
  onClose: () => void;
}

export function ActivitySelection(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outsideClickRef = useOutsideClickRef({ onOutsideClick: props.onClose });
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );

  const { data: dynamicActivities } = useDynamicActivitiesByMentalStateId();

  const { initialSelectedActivity, activitiesList } = getActivitySelectionState(
    dynamicActivities,
    sessionDynamicActivity,
  );

  const [localDynamicSelectedActivity, setLocalDynamicActivity] = useState(initialSelectedActivity);

  useEffect(() => {
    Analytics.logEventWithProperties(Events.activity_selection_opened, {});
  }, []);

  const handleSelectDynamicActivity = (activity: DynamicActivity) => {
    setLocalDynamicActivity(activity);
  };

  const handleApplyChanges = () => {
    dispatch(
      changeSessionDynamicActivity({
        navigate,
        activity: localDynamicSelectedActivity!,
      }),
    );

    handleActivityAnalytics({
      localSelectedActivity: localDynamicSelectedActivity!,
      stateSelectedActivity: localDynamicSelectedActivity!,
    });

    props.onClose();
  };

  const hasActivityChanged = localDynamicSelectedActivity?.id !== sessionDynamicActivity?.id;

  return (
    <S.Wrapper ref={outsideClickRef} data-testid="activityPanel">
      <S.Container>
        <S.Panel>
          <S.HeadingContainer>
            <S.HeaderTitle>Activity</S.HeaderTitle>
            <S.HeaderDescription>
              Select the Activity that fits your needs best.
            </S.HeaderDescription>
          </S.HeadingContainer>
          <S.SectionWrapper>
            <ActivitySettingsDynamicDisplay
              activities={activitiesList}
              selectedActivityId={localDynamicSelectedActivity?.id || ''}
              onSelectActivity={handleSelectDynamicActivity}
            />
          </S.SectionWrapper>
        </S.Panel>
      </S.Container>
      <S.Bottom>
        <Button
          data-testid="activity-discard-changes"
          isFullWidth
          keepTextCase
          style={{ fontSize: '0.75rem', height: '48px' }}
          variant={ButtonVariants.Base}
          onClick={props.onClose}
        >
          CANCEL
        </Button>
        <Button
          data-testid="activity-apply-changes"
          disabled={!hasActivityChanged}
          isFullWidth
          keepTextCase
          style={{ fontSize: '0.75rem', height: '48px' }}
          variant={ButtonVariants.Secondary}
          onClick={handleApplyChanges}
        >
          APPLY
        </Button>
      </S.Bottom>
    </S.Wrapper>
  );
}
