import { trackResetTimer, useResetTimer, useTurnOnPomodoroTimer } from '@Timer';
import { userActions, UserPreferenceDisplayTypes, useUser, useDefaultDisplayType } from '@User';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as analyticsActions from '../../../../actions/analytics';
import {
  sessionTimerChanged,
  setSessionTimer,
  setSessionTimerLengthDisplayValue,
  setSessionType,
} from '../../../../actions/sessionManager';
import { usePlayerAnalyticsParams } from '../../../../hooks/usePlayerAnalyticsParams';
import { RootReducerType } from '../../../../reducers';
import { MentalStates } from '../../../../types';
import { trackTimerSettingsOpened } from '../../../Analytics/coreAnalytics';
import { TimerOrigin } from '../../../Analytics/coreAnalytics.types';
import { useStoreCustomTimerOption } from '../../../Timer/useStoreCustomTimerOption';
import { useStorePomodoroIntervals } from '../../../Timer/useStorePomodoroIntervals';
import { useDynamicSession } from '../../lenses/useDynamicSession';
import { useDefaultTimerPane } from './hooks/useDefaultTimerPane';
import { getTimerValidValue } from './lib/getTimerValidValue';
import { TimeModeDisplay } from './TimeMode.display';
import { TimerMode, useTrackTimerModeChange } from './hooks/useTrackTimerModeChange';
import { TimerPomodorIntervals, TimerSettingsPanes, TimerState } from './types';

type Props = {
  onClose: () => void;
};

export enum TimeUnits {
  Mins = 'mins',
  Hrs = 'hrs',
}

const MaxTimerLengthInHrs = 24;

export function TimeMode(props: Props) {
  const dispatch = useDispatch();
  const handleTrackTimerModeChange = useTrackTimerModeChange();
  const customTimerLength = useSelector(
    (state: RootReducerType) => state.sessionManager.timerLength,
  );
  const intervalTimerBreakType = useSelector(
    (state: RootReducerType) => state.userV2.preferences.intervalTimerBreakType,
  );
  const sessionTimeMode = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayType,
  );

  const persistedPomodoroIntervals = useSelector(
    (state: RootReducerType) => state.timer.pomodoroSettings.intervals,
  );
  const customTimerOptions = useSelector(
    (state: RootReducerType) => state.timer.customTimerOptions,
  );
  const resetTimer = useResetTimer({ shouldRestartRunningTimers: true });
  const turnOnPomodoroTimer = useTurnOnPomodoroTimer();
  const storePomodoroIntervals = useStorePomodoroIntervals();
  const storeCustomTimerOption = useStoreCustomTimerOption();
  useTrackOpenAndCloseOnMountAndDismount();
  const defaultTimerPane = useDefaultTimerPane();

  const [timeUnit, setTimeUnit] = useState<TimeUnits>(TimeUnits.Mins);
  const [timerPane, setTimerPane] = useState(defaultTimerPane);
  const [quotesActive, setQuotesActive] = useState(sessionTimeMode === 'QUOTES' || false);
  const [activeTimeSelected, setActiveTimeSelected] = useState<TimerState | undefined>(undefined);
  const [activePomodoroIntervals, setActivePomodoroIntervals] = useState<TimerPomodorIntervals>({
    focus: { label: 'custom', value: persistedPomodoroIntervals.focusTime },
    break: { label: 'custom', value: persistedPomodoroIntervals.breakTime },
  });
  const [showTimerInformation, setShowTimerInformation] = useState(false);

  const isApplyButtonDisabled = useMemo(() => {
    if (timerPane === TimerSettingsPanes.Timer) {
      return activeTimeSelected?.value === '' || activeTimeSelected?.value === undefined;
    } else if (timerPane === TimerSettingsPanes.Intervals) {
      return (
        activePomodoroIntervals.focus.value === '' ||
        activePomodoroIntervals.focus.value === undefined ||
        activePomodoroIntervals.break.value === '' ||
        activePomodoroIntervals.focus.value === undefined
      );
    }
    return false;
  }, [timerPane, activeTimeSelected, activePomodoroIntervals]);

  const handleStartInfiniteSession = useCallback(() => {
    if (quotesActive) {
      dispatch(setSessionType('QUOTES'));
      dispatch(
        userActions.setDefaultDisplayType({
          type: UserPreferenceDisplayTypes.Quotes,
        }),
      );
      handleTrackTimerModeChange({ timerMode: TimerMode.Quotes });
    } else {
      dispatch(setSessionType('NORMAL'));
      dispatch(trackResetTimer());
      resetTimer();
      dispatch(
        userActions.setDefaultDisplayType({
          type: UserPreferenceDisplayTypes.Infinite,
        }),
      );
      handleTrackTimerModeChange({ timerMode: TimerMode.Infinite });
    }
    dispatch(setSessionTimerLengthDisplayValue('Infinite'));
    props.onClose();
  }, [dispatch, quotesActive, handleTrackTimerModeChange, resetTimer, props.onClose]);

  const normalizePomodoroIntervals = useCallback(
    (intervals: TimerPomodorIntervals) => {
      return {
        focus: {
          label: intervals.focus.label || 'custom',
          value: ['', '0'].includes(intervals.focus.value)
            ? persistedPomodoroIntervals.focusTime
            : intervals.focus.value,
        },
        break: {
          label: intervals.focus.label || 'custom',
          value: ['', '0'].includes(intervals.break.value)
            ? persistedPomodoroIntervals.breakTime
            : intervals.break.value,
        },
      };
    },
    [persistedPomodoroIntervals],
  );

  const handleStartPomodoroSession = useCallback(() => {
    dispatch(setSessionType('NORMAL'));

    const pomodoroIntervals = normalizePomodoroIntervals(activePomodoroIntervals);
    setActivePomodoroIntervals(pomodoroIntervals);
    dispatch(
      setSessionTimerLengthDisplayValue(
        `${pomodoroIntervals.focus.label}, ${pomodoroIntervals.break.label}`,
      ),
    );
    storePomodoroIntervals({
      focusTime: pomodoroIntervals.focus.value,
      breakTime: pomodoroIntervals.break.value,
    });
    resetTimer();
    turnOnPomodoroTimer();
    dispatch(
      userActions.setDefaultDisplayType({
        type: UserPreferenceDisplayTypes.Pomodoro,
      }),
    );
    handleTrackTimerModeChange({
      timerMode: TimerMode.Interval,
      customTimerLength: customTimerLength,
      props: {
        pomodoroIntervals: {
          focusTime: pomodoroIntervals.focus.value,
          breakTime: pomodoroIntervals.break.value,
          soundType: intervalTimerBreakType,
        },
      },
    });
    props.onClose();
  }, [
    dispatch,
    normalizePomodoroIntervals,
    activePomodoroIntervals,
    storePomodoroIntervals,
    resetTimer,
    turnOnPomodoroTimer,
    customTimerLength,
    handleTrackTimerModeChange,
    props.onClose,
  ]);

  const handleStartTimedSession = useCallback(
    (value: string, label?: string) => {
      const timerLength = 60 * parseInt(value, 10);
      dispatch(
        setSessionTimer({
          sessionPlayType: 'TIMER',
          timerLength,
          timerLengthDisplayValue: label,
        }),
      );
      handleTrackTimerModeChange({ timerMode: TimerMode.Custom, customTimerLength: timerLength });

      dispatch(setSessionTimerLengthDisplayValue(label || ''));
      props.onClose();
    },
    [dispatch, handleTrackTimerModeChange, props.onClose],
  );

  const handleActiveTimeSelected = useCallback(
    (time: TimerState) => {
      const value = getTimerValidValue({
        maxTimerLengthInHrs: MaxTimerLengthInHrs,
        timeUnit,
        time,
      });

      if (value !== undefined) {
        setActiveTimeSelected({
          ...time,
          value,
        });
      }
    },
    [timeUnit],
  );

  const handleChangeTimeUnit = useCallback(
    (timeUnit: TimeUnits) => {
      if (timeUnit === TimeUnits.Hrs && activeTimeSelected) {
        const value = getTimerValidValue({
          maxTimerLengthInHrs: MaxTimerLengthInHrs,
          timeUnit,
          time: activeTimeSelected,
        });
        if (value !== undefined) {
          setActiveTimeSelected({
            ...activeTimeSelected,
            value,
          });
        }
      }
      setTimeUnit(timeUnit);
    },
    [activeTimeSelected, setActiveTimeSelected],
  );

  const handleSetPomodoroIntervals = useCallback((intervals: TimerPomodorIntervals) => {
    setActivePomodoroIntervals(state => ({
      ...state,
      focus: {
        ...state.focus,
        value:
          typeof intervals.focus.value !== 'undefined' && /^(\s*|\d+)$/.test(intervals.focus.value)
            ? intervals.focus.value
            : state.focus.value,
      },
      break: {
        ...state.break,
        value:
          typeof intervals.break.value !== 'undefined' && /^(\s*|\d+)$/.test(intervals.break.value)
            ? intervals.break.value
            : state.break.value,
      },
    }));
  }, []);

  const handleTimePane = useCallback((pane: TimerSettingsPanes) => {
    setTimerPane(pane);
  }, []);

  const handleSetQuoteActive = useCallback((value: boolean) => {
    setQuotesActive(value);
  }, []);

  const handlePomodoroWork = useCallback((time: TimerState) => {
    setActivePomodoroIntervals(interval => ({
      focus: time,
      break: interval.break || undefined,
    }));
  }, []);

  const handlePomodoroRest = useCallback((time: TimerState) => {
    setActivePomodoroIntervals(interval => ({
      focus: interval.focus || undefined,
      break: time,
    }));
  }, []);

  const handleApplyTimer = useCallback(() => {
    if (timerPane === TimerSettingsPanes.Infinite) {
      handleStartInfiniteSession();
    } else if (timerPane === TimerSettingsPanes.Timer) {
      let newValue: { value: string; label: string };
      if (timeUnit === 'hrs') {
        if (activeTimeSelected?.value.includes('.')) {
          newValue = {
            value: `${parseFloat(activeTimeSelected?.value || '0') * 60}`,
            label: `${activeTimeSelected?.value} hr`,
          };
        } else {
          newValue = {
            value: `${parseInt(activeTimeSelected?.value || '0') * 60}`,
            label: `${activeTimeSelected?.value} hr`,
          };
        }
      } else {
        if (activeTimeSelected?.label.includes('custom')) {
          newValue = {
            value: activeTimeSelected?.value || '0',
            label: `${activeTimeSelected?.value} min`,
          };
        } else {
          newValue = {
            value: activeTimeSelected?.value || '0',
            label: `${activeTimeSelected?.label}`,
          };
        }
      }

      handleStartTimedSession(newValue.value, newValue.label);
      storeCustomTimerOption(newValue);
    } else if (timerPane === TimerSettingsPanes.Intervals) {
      handleStartPomodoroSession();
    }
    dispatch(sessionTimerChanged());
  }, [
    storeCustomTimerOption,
    timerPane,
    timeUnit,
    activeTimeSelected,
    handleStartInfiniteSession,
    handleStartTimedSession,
    handleStartPomodoroSession,
  ]);

  return (
    <TimeModeDisplay
      activePomodoroIntervals={activePomodoroIntervals}
      activeTimeSelected={activeTimeSelected}
      customTimerOptions={customTimerOptions}
      isApplyButtonDisabled={isApplyButtonDisabled}
      isQuotesActive={quotesActive}
      sessionTimeMode={sessionTimeMode}
      showTimerInformation={showTimerInformation}
      timerPane={timerPane}
      timerUnit={timeUnit}
      onApplyTimer={handleApplyTimer}
      onClose={props.onClose}
      onSetActivePomodoroIntervals={handleSetPomodoroIntervals}
      onSetActiveTimeSelected={handleActiveTimeSelected}
      onSetPomodoroRest={handlePomodoroRest}
      onSetPomodoroWork={handlePomodoroWork}
      onSetQuotesActive={handleSetQuoteActive}
      onSetShowTimerInformation={value => setShowTimerInformation(value)}
      onSetTimerPane={handleTimePane}
      onSetTimerUnit={handleChangeTimeUnit}
    />
  );
}

function useTrackOpenAndCloseOnMountAndDismount() {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useDynamicSession();
  const currentPreferences = useRef(
    session ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus] : null,
  );
  const {
    activity,
    trackGenre,
    filterGenre,
    filterNel,
    sessionModalContentOrigin,
    trackNel,
    mentalState,
  } = usePlayerAnalyticsParams();

  useEffect(() => {
    if (currentPreferences.current) {
      trackTimerSettingsOpened({
        activity,
        trackGenre,
        filterGenre,
        mentalState,
        trackNel,
        filterNel,
        origin: sessionModalContentOrigin as TimerOrigin,
      });
    }
    dispatch(analyticsActions.playerLogEvent('player_timer_open'));

    return () => {
      dispatch(analyticsActions.playerLogEvent('player_timer_close'));
    };
  }, []);
}
