import { useSelector } from 'react-redux';

import { useIsPomodoroActive } from '@Timer';
import { RootReducerType } from '../../../../../reducers';
import { getTimerMode } from '../../../../../utils/getTimerMode';
import { TimerSettingsPanes } from '../types';
import { TimerMode } from './useTrackTimerModeChange';

export function useDefaultTimerPane() {
  const { sessionPlayType } = useSelector((state: RootReducerType) => state.sessionManager);
  const isPomodoro = useIsPomodoroActive();
  const timerMode = getTimerMode(sessionPlayType, isPomodoro);

  switch (timerMode) {
    case TimerMode.Infinite:
      return TimerSettingsPanes.Infinite;
    case TimerMode.Custom:
      return TimerSettingsPanes.Timer;
    case TimerMode.Interval:
      return TimerSettingsPanes.Intervals;
    default:
      return TimerSettingsPanes.Infinite;
  }
}
