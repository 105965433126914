import styled from 'styled-components';

export const Container = styled.div<{ padding: string }>`
  width: 100%;
  display: flex;
  min-height: 90px;
  flex-direction: row;
  padding: ${props => props.padding};
`;

export const EmptyContainer = styled.div`
  flex: 1;
`;

export const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CloseIconContainer = styled.div`
  backdrop-filter: blur(34px);
  background: linear-gradient(0deg, rgba(25, 23, 54, 0.5), rgba(25, 23, 54, 0.5));
  border-radius: 100%;
  border: none;
  box-shadow: inset 0px 0px 0px 1px #565487;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  isolation: isolate;
  outline: none;
  transition: box-shadow 0.2s ease-in-out;
  width: 3.5rem;
  z-index: 99;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #565487;
  }
`;

export const CloseIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;
