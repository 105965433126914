import styled from 'styled-components';

export const AudioControlWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  z-index: 99;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
  }
`;
