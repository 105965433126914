import {
  audioPlayerActions,
  useIsAudioPlayerRepeatEnabled,
  useToggleAudioPlayerRepeat,
} from '@AudioPlayer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBackgroundImage } from '../../../../../../components/session/utils/getBackgroundImage';
import useImagesPreloader from '../../../../../../hooks/useImagesPreloader';

import { usePlayerAnalyticsParams } from '../../../../../../hooks/usePlayerAnalyticsParams';
import { useTimerMode } from '../../../../../../hooks/useTimerMode';
import { RootReducerType } from '../../../../../../reducers';
import {
  trackPreviousTrackEvent,
  trackSkipTrackEvent,
} from '../../../../../Analytics/coreAnalytics';
import { useGetPreviousTrack } from '../../../../../Music/lenses/useGetPreviousTrack';
import * as S from './TrackControls.styles';
import PauseIcon from './assets/pause_icon.svg';
import PlayIcon from './assets/play_icon.svg';
import RepeatIcon from './assets/repeat_icon.svg';
import SkipIcon from './assets/skip_icon.svg';

const AMOUNT_OF_TRACKS_IMAGES_TO_PRELOAD = 2;

export const TrackControls = () => {
  const isAudioPlayerRepeatEnabled = useIsAudioPlayerRepeatEnabled();
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const toggleAudioPlayerRepeat = useToggleAudioPlayerRepeat();
  const timerMode = useTimerMode();
  const tracks = useSelector((state: RootReducerType) => state.music.queue);
  const [imagesToPreload, setImagesToPreload] = useState<string[]>([]);
  useImagesPreloader(imagesToPreload);

  const previousTrack = useGetPreviousTrack();
  const sessionPlayStatus = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayStatus,
  );
  const {
    activity,
    trackGenre,
    filterGenre,
    filterNel,
    trackNel,
    complexity,
    trackName,
    mentalState,
  } = usePlayerAnalyticsParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // Preload images only when tracks are updated
    setImagesToPreload(
      tracks.slice(0, AMOUNT_OF_TRACKS_IMAGES_TO_PRELOAD).map(track => getBackgroundImage(track)),
    );
  }, [tracks]);

  const emmitEvent = (name: string) => {
    window.dispatchEvent(new Event(name));
  };

  const handlePrevious = () => {
    if (previousTrack) {
      dispatch(audioPlayerActions.setPlayStyleToDefault());
      emmitEvent('previous');
      if (!currentTrack) return;
      trackPreviousTrackEvent({
        activity,
        complexity,
        trackGenre,
        filterGenre,
        mentalState,
        trackNel,
        filterNel,
        timerMode,
        trackName,
      });
    }
  };
  const handleSkip = () => {
    if (tracks && tracks[0]) {
      dispatch(audioPlayerActions.setPlayStyleToDefault());
      emmitEvent('skip');
      if (!currentTrack) return;
      trackSkipTrackEvent({
        activity,
        complexity,
        trackGenre,
        filterGenre,
        mentalState,
        trackNel,
        filterNel,
        timerMode,
        trackName,
      });
    }
  };

  const handleClick = () => {
    if (sessionPlayStatus === 'UPDATING' || sessionPlayStatus === 'EMPTY') return;

    if (sessionPlayStatus === 'PLAYING') {
      return emmitEvent('pause');
    } else {
      return emmitEvent('resume');
    }
  };

  let buttonState = 'loading';

  if (sessionPlayStatus === 'PLAYING') {
    buttonState = 'playing';
  } else if (sessionPlayStatus === 'UPDATING' || sessionPlayStatus === 'EMPTY') {
    buttonState = 'loading';
  } else {
    buttonState = 'paused';
  }

  return (
    <S.ControlButtons>
      <S.RepeatRow>
        <S.RepeatButton
          aria-label={isAudioPlayerRepeatEnabled ? 'Disable repeat' : 'Enable repeat'}
          aria-pressed={isAudioPlayerRepeatEnabled}
          data-testid="repeatButton"
          isActive={isAudioPlayerRepeatEnabled}
          onClick={toggleAudioPlayerRepeat}
        >
          <S.IconWrapper>
            <S.Image alt="" aria-hidden="true" src={RepeatIcon} />
          </S.IconWrapper>
        </S.RepeatButton>
      </S.RepeatRow>

      <S.ControlsRow>
        <S.PreviousButton
          aria-disabled={!previousTrack}
          aria-label="Previous track"
          data-testid="previousButton"
          isDisabled={!previousTrack}
          onClick={handlePrevious}
        >
          <S.PreviousIconWrapper>
            <S.Image alt="" aria-hidden="true" src={SkipIcon} />
          </S.PreviousIconWrapper>
        </S.PreviousButton>

        <S.PlayPauseButton
          aria-disabled={buttonState === 'loading'}
          aria-label={buttonState === 'playing' ? 'Pause' : 'Play'}
          data-testid="playPauseButton"
          disabled={buttonState === 'loading'}
          isLoading={buttonState === 'loading'}
          onClick={handleClick}
        >
          <S.ImageContainer>
            {buttonState !== 'loading' ? (
              buttonState === 'playing' ? (
                <S.Image alt="" aria-hidden="true" data-testid="pauseIcon" src={PauseIcon} />
              ) : (
                <S.Image alt="" aria-hidden="true" data-testid="playIcon" src={PlayIcon} />
              )
            ) : null}
          </S.ImageContainer>
        </S.PlayPauseButton>

        <S.SkipButton aria-label="Skip track" data-testid="skipButton" onClick={handleSkip}>
          <S.IconWrapper>
            <S.Image alt="" aria-hidden="true" src={SkipIcon} />
          </S.IconWrapper>
        </S.SkipButton>
      </S.ControlsRow>
    </S.ControlButtons>
  );
};
