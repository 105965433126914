import { useGetAvailableStripeCouponPromotion, MembershipSources } from '@Memberships';
import * as uiActions from '../../../../../../actions/ui';
import { MembershipPanelWrapper } from '../MembershipPanelWrapper/MembershipPanelWrapper';
import { useIsPaymentProcessing } from '@Payment';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootReducerType } from '../../../../../../reducers';
import { getCouponCallToActionText } from '../../utils/getCouponCallToActionText';
import { ExpiredMembershipCallToActionPresentation } from './ExpiredMembershipCallToAction.presentation';

export const ExpiredMembershipCallAction = () => {
  const navigate = useNavigate();
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const isPaymentProcessing = useIsPaymentProcessing();
  const dispatch = useDispatch();
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const membershipSource = useSelector(
    (state: Pick<RootReducerType, 'membership'>) => state.membership.source,
  );
  const isLoading = membershipSource === MembershipSources.Loading || isPaymentProcessing;
  const onClickRenewMembership = useCallback(() => {
    if (membershipSource === MembershipSources.Stripe) {
      dispatch(uiActions.setModal('fastSubscription'));
    } else {
      navigate('/payment', {
        state: { from: 'Membership Panel Expired' },
      });
    }
  }, [navigate, dispatch, membershipSource]);
  const subtitle = `${membershipTitle} Plan`;

  return (
    <MembershipPanelWrapper
      isLoading={isLoading}
      subtitle={subtitle}
      tagTitle="EXPIRED"
      title="PRO"
    >
      <ExpiredMembershipCallToActionPresentation
        callToActionText={getCouponCallToActionText(availablePromotion)}
        onClickRenewMembership={onClickRenewMembership}
      />
    </MembershipPanelWrapper>
  );
};
