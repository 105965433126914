import { audioPlayerReducer, AudioPlayerState } from '@AudioPlayer';
import { membershipReducer } from '@Memberships';
import { MembershipState } from '@Memberships';
import { persistedMusicReducer, MusicState } from '@Music';
import { paymentReducer, PaymentState } from '@Payment';
import { teamsReducer, TeamsState } from '@Teams';
import { persistedTimerReducer, TimerState } from '@Timer';
import { persistedUserReducer, UserState } from '@User';
import { recentSessionsPersistReducer, RecentSessionsReducerType } from './recentSessions';
import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';

import {
  AuthStateType,
  AppStateType,
  UserStateType,
  SessionManagerStateType,
  UiStateType,
  CurrentSessionStateType,
  PaymentsStateType,
  MembershipPlansStateType,
} from '../types';
import appReducer from './app';
import authReducer from './authentication';
import currentSessionReducer from './currentSession';
import membershipPlansReducer from './membershipPlans';
import {
  recommendedActivitiesReducer,
  RecommendedActivitiesReducerType,
} from './recommendedActivities';
import sessionManagerReducer from './sessionManager';
import { persistedUIReducer } from './uiReducer';
import userReducer from './user';
import { activationReducer, ActivationState } from '../domains/Activation/reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    activation: activationReducer,
    app: appReducer,
    audioPlayer: audioPlayerReducer,
    auth: authReducer,
    currentSession: currentSessionReducer,
    membership: membershipReducer,
    membershipPlans: membershipPlansReducer,
    music: persistedMusicReducer,
    paymentsV2: paymentReducer,
    recentSessions: recentSessionsPersistReducer,
    recommendedActivities: recommendedActivitiesReducer,
    sessionManager: sessionManagerReducer,
    teams: teamsReducer,
    timer: persistedTimerReducer,
    ui: persistedUIReducer,
    user: userReducer,
    userV2: persistedUserReducer,
  });

export interface RootReducerType {
  activation: ActivationState;
  app: AppStateType;
  audioPlayer: AudioPlayerState;
  auth: AuthStateType;
  currentSession: CurrentSessionStateType;
  membership: MembershipState;
  membershipPlans: MembershipPlansStateType;
  music: MusicState;
  payments: PaymentsStateType;
  paymentsV2: PaymentState;
  recentSessions: RecentSessionsReducerType;
  recommendedActivities: RecommendedActivitiesReducerType;
  sessionManager: SessionManagerStateType;
  teams: TeamsState;
  timer: TimerState;
  ui: UiStateType;
  user: UserStateType;
  userV2: UserState;
}

export default createRootReducer;
