import { DynamicActivity } from '@Model';
import { Assets } from '../../../../utils/assets';

export const getActivityImage = (activity: DynamicActivity | null) => {
  if (!activity) return null;

  // Default to the mental state background image if available
  if (activity.mentalState?.imageUrl) {
    return {
      url: activity.mentalState.imageUrl,
      alt: `${activity.displayValue} activity image`,
    };
  }

  // Fallback mapping for specific activities
  const activityImageMap: Record<string, string> = {
    'Deep Work': Assets.images.focusMentalStateBgWebp.url,
    'Deep Sleep': Assets.images.sleepMentalStateBgWebp.url,
    Recharge: Assets.images.relaxMentalStateBgWebp.url,
    Meditate: Assets.images.meditateMentalStateBgWebp.url,
  };

  const fallbackImage = activityImageMap[activity.displayValue] || Assets.images.threeClocks.url;

  return {
    url: fallbackImage,
    alt: `${activity.displayValue} activity image`,
  };
};
