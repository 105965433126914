import { MembershipStates, MembershipSources, MembershipState } from '@Memberships';
import { StripeMembershipCycle } from '../../../../../../../utils/getStripeMembershipCycle';

type Props = {
  membership: MembershipState;
  membershipTitle?: string;
  stripeMembershipCycle: StripeMembershipCycle;
};

export const getCtaText = ({ membership, membershipTitle, stripeMembershipCycle }: Props) => {
  if (
    [
      StripeMembershipCycle.FreeTrialNotRenewingActive,
      StripeMembershipCycle.PaidNotRenewingActive,
    ].includes(stripeMembershipCycle)
  ) {
    return 'KEEP MY ACCESS';
  }

  if (
    membership.source === MembershipSources.Stripe &&
    membership.state === MembershipStates.Cancelled
  ) {
    return 'RENEW SUBSCRIPTION';
  }
  if (membership.source === MembershipSources.Stripe && membershipTitle === 'Monthly') {
    return 'UPGRADE TO YEARLY';
  }
  return null;
};
